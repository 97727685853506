<template>
  <div class="main" v-if="currentAssessment">
    <div class="title-container">
      <span>{{ currentAssessment.name }}</span>
      <span style="margin: 0 20px; border-right: 1px solid #000000; height: 20px;"></span>
      <span style="font-size: 14px !important;">Time Elapsed: <span style="font-family: VWHead Bold; color: #00B0F0">{{ timeDisplay }}</span></span>
    </div>
    <div class="body">
        <div class="panel">
          <div class="panel-label">Question {{ currentQuestionNumber + 1 }}/{{ currentAssessment.questions.length }}</div>
          <div class="panel-body" style="margin-bottom: 35px;">
            <div class="panel-row">
              <div class="panel-column">
                <span style="font-size: 20px;">{{ activeQuestion.text }}</span>
              </div>
            </div>
            <div v-for="(answer, index) in activeQuestion.answers" :key="answer.id" :class="{'panel-row':true, last : index === (activeQuestion.answers.length-1)}">
                <button :class="answer.selected === 1 ? 'radio-selected' : 'radio'" @click="selectAnswer(index)">
                <div v-if="answer.selected === 1" class="selected"></div>
              </button>
              <span style="font-family:'VWText Bold'">{{alphabetArray[index]}}.</span>
              <span style="margin-left: 5px;">{{answer.text}}</span>
            </div>
          </div>
        <button v-if="currentQuestionNumber < currentAssessment.questions.length - 1" style="width: 170px;" @click="nextQuestion()">Next Question</button>
        <button v-if="currentQuestionNumber === currentAssessment.questions.length - 1" style="width: 220px;" @click="publish()">Save & Publish</button>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'preview',
  props: {
    currentAssessment: Object,
  },
  data() {
    return {
      currentQuestionNumber: 0,
      time: 0,
      isRunning: false,
      interval: null,
    };
  },
  methods: {
    nextQuestion() {
      this.currentQuestionNumber += 1;
    },
    selectAnswer(answer) {
      for (let i = 0; i < this.activeQuestion.answers.length; i += 1) {
        if (this.activeQuestion.answers[i] === this.activeQuestion.answers[answer]) {
          this.activeQuestion.answers[i].selected = 1;
          this.$forceUpdate();
        } else {
          this.activeQuestion.answers[i].selected = 0;
          this.$forceUpdate();
        }
      }
    },
    toggleTimer() {
      if (this.isRunning) {
        clearInterval(this.interval);
      } else {
        this.interval = setInterval(this.incrementTime, 1000);
      }
      this.isRunning = !this.isRunning;
    },
    incrementTime() {
      // eslint-disable-next-line radix
      this.time = parseInt(this.time) + 1;
    },
    publish() {
      this.$emit('saveFromPreview');
    },
  },
  computed: {
    activeQuestion() {
      return JSON.parse(JSON.stringify(this.currentAssessment.questions[this.currentQuestionNumber]));
    },
    previousRoute() {
      return this.$store.state.previousRoute;
    },
    timeDisplay() {
      if (this.time) {
        const hours = this.time / 3600;
        let rhours = Math.floor(hours);
        const minutes = (hours - rhours) * 60;
        let rminutes = Math.floor(minutes);
        let seconds = this.time % 60;
        if (rhours > 10) {
          rhours = `0${rhours}`;
        }
        if (rminutes < 10) {
          rminutes = `0${rminutes}`;
        }
        if (seconds < 10) {
          seconds = `0${seconds}`;
        }
        if (rhours > 0) {
          return `${rhours}:${rminutes}:${seconds}`;
        }
        return `${rminutes}:${seconds}`;
      }
      return null;
    },
    alphabetArray() {
      return 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
    },
  },
  created() {
    this.toggleTimer();
  },
};
</script>

<style lang="scss" scoped>
.main {
  margin: 0px;
}
.header {
  background-color: #001E50;
  justify-content: space-between;
  padding: 35px 60px;
}
.header-text{
  font-family: 'VWText Bold';
  font-size: 14px;
  color: white;
}
.title-container {
  display: flex;
  padding: 35px 60px;
  font-size: 20px;
  background-color: #FFFFFF;
  align-items: center;
  border-bottom: 1px solid #DFE4E8;

}
.panel {
  padding: 30px 200px;
  .panel-label {
  font-size: 12px;
  margin-bottom: 15px;
  }
}
.last {
  margin-bottom: 0px !important;
}

</style>
